import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        platformInfo: JSON.parse(localStorage.getItem('platformInfo') || '{}'), //平台信息
        token: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken'),
        userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),    //个人信息
        showLogin: false,    //展示登录
        webViewUrl: sessionStorage.getItem('webViewUrl'),  //游戏路径
    },
    getters: {},
    mutations: {
        setStateVal(state, payload) {
            console.log(state, payload);
            state[payload.prop] = payload.value
        }
    },
    actions: {},
    modules: {}
})
