import axios from 'axios';
import {Message} from 'element-ui';

// 创建axios实例
const service = axios.create({
    // baseURL: window?.configGlobal?.HTTP_HOST || 'https://api.example.com', // API的base_url
    timeout: 5000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        //修改请求地址
        config.baseURL = window?.configGlobal?.HTTP_HOST
        // 在请求头中添加token
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `${token}`; // 让每个请求携带token
        }
        return config;
    },
    error => {
        // 处理请求错误
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data

        // 通过响应码判断请求是否成功
        if (res.code !== 0) {
            Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 5000
            });
            //不抛出异常
        }
        return res
    },
    error => {
        if (error.status === 403) {
            //未登录
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('userInfo')

            location.reload()
            return
        }
        // 处理响应错误
        Message({
            message: error.message || '网络错误，请重试',
            type: 'error',
            duration: 5000
        });
        //不抛出异常
        // return Promise.reject(error);
    }
);

export default service;
