<template>
    <div :style="{height: height + 'px'}" class="containerUseInput flex relative">
        <!-- 左侧插槽 -->
        <slot name="left"></slot>

        <!-- 输入框 -->
        <input @input="updateValue" :value="inputValue" class="flex1 h100" :type="inputType" :style="inputStyle"
               :placeholder="inputPlaceholder"/>

        <!-- 右侧插槽 -->
        <slot name="right"></slot>
    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            height: {   //高度
                require: true,
                type: [String, Number]
            },
            inputValue: {
                require: true
            },
            inputStyle: {   //输入框的样式
                type: Object
            },
            inputType: {    //输入框类型
                type: String,
                default() {
                    return 'text'
                }
            },
            inputPlaceholder: {
                type: String,
                default() {
                    return '请输入内容（默认）'
                }
            }
        },
        methods: {
            updateValue(event) {
                this.$emit('input', event.target.value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .containerUseInput {
        input {
            box-shadow: inset 0px 2px 21px 0px rgba(191, 145, 83, 0.18);
            border-radius: 4px;
        }
    }
</style>
