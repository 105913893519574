var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"containerHall"},[_vm._m(0),_c('div',{staticClass:"marketing flex justifyContentSpaceBetween"},[_c('div',{staticClass:"item1 relative"},[_c('img',{staticClass:"absolute",staticStyle:{"width":"119px","height":"47px","right":"6px","top":"13px"},attrs:{"src":"/images/home/jiaobiao.png","alt":""}}),_c('p',{staticClass:"font14 color333 absolute",staticStyle:{"top":"115px","left":"75px"}},[_vm._v("497桌")]),_c('p',{staticClass:"font14 absolute",staticStyle:{"top":"150px","left":"75px","color":"#3e5582"}},[_vm._v("1635人在线")]),_c('div',{staticClass:"font16 colorfff absolute w100",staticStyle:{"top":"203px","left":"50px","height":"38px"}},[_c('el-carousel',{attrs:{"height":"38px","direction":"vertical","autoplay":true}},_vm._l((_vm.carousel),function(item,index){return _c('el-carousel-item',{key:index},[_c('span',{staticClass:"font14"},[_vm._v(_vm._s(item))])])}),1)],1)]),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"flex justifyContentSpaceBetween",staticStyle:{"margin-top":"50px"}},_vm._l((_vm.navBar),function(item,index){return _c('img',{key:index,staticStyle:{"width":"267px"},attrs:{"src":`/images/home/${item.icon}_i.png`,"alt":""}})}),0),_vm._m(3),_c('div',{staticClass:"introduce flex justifyContentSpaceBetween",staticStyle:{"margin-top":"90px"}},[_c('div',{staticClass:"flex1 left"},[_vm._m(4),_c('div',{staticClass:"appDownLoad borderBox relative textAlignCenter"},[_c('p',{staticClass:"font14",staticStyle:{"color":"#6c4f62"}},[_vm._v("手机扫描下载")]),_c('img',{staticClass:"absolute",attrs:{"src":_vm.appDownLoadImg,"alt":""}}),_vm._m(5)])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textAlignCenter"},[_c('img',{staticStyle:{"width":"1144px"},attrs:{"src":"/images/home/yxdt_title.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item2 relative"},[_c('p',{staticClass:"font28 absolute",staticStyle:{"top":"45px","left":"75px","color":"#39270c"}},[_vm._v("体育")]),_c('p',{staticClass:"font14 absolute",staticStyle:{"top":"105px","left":"25px","color":"#39270c"}},[_vm._v(" 业界最高盘口 ")]),_c('p',{staticClass:"font14 absolute",staticStyle:{"top":"130px","left":"25px","color":"#39270c"}},[_vm._v(" 赛事高清直播 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item3 relative"},[_c('p',{staticClass:"font28 absolute",staticStyle:{"top":"45px","left":"75px","color":"#39270c"}},[_vm._v("捕鱼")]),_c('p',{staticClass:"font14 absolute",staticStyle:{"top":"105px","left":"25px","color":"#39270c"}},[_vm._v(" 群雄逐鱼 ")]),_c('p',{staticClass:"font14 absolute",staticStyle:{"top":"130px","left":"25px","color":"#39270c"}},[_vm._v(" 赚到你手软 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textAlignCenter",staticStyle:{"margin-top":"65px"}},[_c('img',{staticStyle:{"width":"1144px"},attrs:{"src":"/images/home/jzty_title.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font20",staticStyle:{"color":"#3e5075","line-height":"32px"}},[_c('p',{staticClass:"font36"},[_vm._v("天子俱乐部APP")]),_c('p',{staticStyle:{"margin-top":"40px"}},[_vm._v("综合手机客户端，便捷的登录方式，丰富的游戏品")]),_c('p',[_vm._v("类，应有尽有的游戏玩法，24小时多平台无缝应用")]),_c('p',[_vm._v("体验，带您开启掌上精彩！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font12 absolute w100"},[_c('span',[_vm._v("手机快速访问")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{staticStyle:{"width":"636px"},attrs:{"src":"/images/home/h_sj.png","alt":""}})])
}]

export { render, staticRenderFns }