<template>
    <div class="containerHome">
        <div class="relative" style="z-index: 9;">
            <HeaderComponent></HeaderComponent>
        </div>
        <div style="margin-top:2px;">
            <carousel></carousel>
        </div>
        <div class="marginAuto" style="width:1200px;">
            <div style="margin-top: 25px;">
                <notice></notice>
            </div>
            <div style="margin-top: 40px;">
                <hall></hall>
            </div>
        </div>
        <FooterComponent></FooterComponent>
    </div>
</template>

<script>
    import {gameConfigApi} from "@/api";

    import carousel from "./components/carousel";
    import notice from "./components/notice";
    import hall from "./components/hall";

    export default {
        name: "index",
        components: {carousel, notice, hall},
        data() {
            return {}
        },
        created() {
            this.useInterceptedOnMounted(this.init)
        },
        methods: {
            init() {
                gameConfigApi().then(res => {
                    //缓存平台信息
                    localStorage.setItem('platformInfo', JSON.stringify(res.data))
                    this.setStoreState({
                        prop: 'platformInfo',
                        value: res.data
                    })
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .containerHome {
        background: url("@images/home/h_big_bg.png") no-repeat top center/100.2% 100%;
    }
</style>
