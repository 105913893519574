<template>
    <div class="container w100 marginAuto">
        <div class="notice">
            <div class="w100 flex borderBox">
                <img style="width:51px;height: 49px;" src="/images/home/ad_img.png" alt="">
                <div class="flex1 h100 flex alignItemsCenter">
                    <marquee>{{platformInfo.message || '棋牌游戏、电子竞技等多种产品。祝您盈利多多！'}}</marquee>
                </div>
            </div>
        </div>
        <div class="recently flex">
            <div class="h100 flex alignItemsCenter">
                <img style="width: 23px;height: 23px;margin-right: 5px;" src="/images/home/shijian.png" alt="">
                <span class="font18 color333">最近在玩</span>
            </div>
            <div class="flex1 h100 flex alignItemsCenter justifyContentSpaceAround">
                <img v-for="(item,index) in recentlyList" :key="index" :src="`/images/home/zj_${item.icon}.png`"
                     class="pointer hover"
                     style="width:242px;" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import {qrcanvas} from "qrcanvas";

    export default {
        name: "notice",
        computed: {...mapState(['platformInfo'])},
        data() {
            const recentlyList = [
                {icon: 'caipiao'},
                {icon: 'qipai'},
                {icon: 'dianjing'},
                {icon: 'tiyu'}
            ]
            return {recentlyList}
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        .notice {
            height: 70px;
            background: url("@images/home/ad_bg.png") no-repeat center center/cover;

            > div {
                height: 55px;
                padding: 0px 26px;
            }
        }

        .recently {
            height: 105px;
            padding-left: 43px;
            background: url("@images/home/zjzw_bg.png") no-repeat center center/cover;
        }

    }
</style>
