import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import HeaderComponent from './components/header'
import FooterComponent from './components/footer'
import UseInputComponent from './components/useInput'

// 全局注册组件
Vue.component('HeaderComponent', HeaderComponent)
Vue.component('FooterComponent', FooterComponent)
Vue.component('UseInputComponent', UseInputComponent)


import mixins from './mixins'

Vue.mixin(mixins);  // 注册全局 Mixin

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
