<template>
    <div>
        <el-carousel :interval="500000000" arrow="always" :height="carouselHeight + 'px'">
            <el-carousel-item v-for="(item,index) in (platformInfo.images || [])" :key="index">
                <img class="w100" :src="item" :style="{height:carouselHeight + 'px'}" alt="">
            </el-carousel-item>
            <el-carousel-item>
                <img ref="modeImg" class="w100" src="/images/home/banner01.png" alt="">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
    import {mapState} from "vuex"

    export default {
        name: "carousel",
        computed: {...mapState(['platformInfo'])},
        data() {
            return {
                carouselHeight: ''
            }
        },
        created() {
            this.useInterceptedOnMounted(this.init)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.countHeight)
        },
        methods: {
            init() {
                this.countHeight()
                this.resize()
            },
            resize() {
                window.addEventListener('resize', this.countHeight)
            },
            countHeight() {
                const img = this.$refs['modeImg']
                if (img && img.complete) {
                    this.carouselHeight = img.height
                } else if (img) {
                    img.onload = () => {
                        this.carouselHeight = img.height
                    }
                } else {
                    setTimeout(() => {
                        this.countHeight()
                    }, 100)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .el-carousel__button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #a2afc4;
        }

        .el-carousel__indicator--horizontal {
            padding: 12px 10px;
        }

        .el-carousel__indicator.is-active button {
            background-color: #484d50;
        }

        .el-carousel--horizontal {
            overflow-y: hidden;
        }


        //隐藏指示器
        .el-carousel__arrow {
            display: none;
        }
    }
</style>
